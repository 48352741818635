import videojs from "video.js";

window.addEventListener("DOMContentLoaded", function () {
  var isHeroVideo = [...this.document.querySelectorAll(".video-js")].map(
    (x) => x.id
  );
  if (isHeroVideo.length) {
    isHeroVideo.forEach((video) => {
      if (!video) return;
      // Only keep the video and do not render any other child components of video.js
      // More info about component structure here: https://videojs.com/guides/components/#default-component-tree
      var player = videojs(video, {
          children: ["MediaLoader"]
      });

        var videoEl = document.querySelector("#" + video);

        // if the videofit is contain add the object-fit:contain class. The default is object-fit:cover.
        if (videoEl && videoEl.classList.contains("hero__video-container-contain")) {
            // This assumes that the first element is the video element with vjs-tech class.
            var vjsEl = videoEl.firstElementChild;
            if (vjsEl) {
                vjsEl.classList.add("hero__video-container-contain");
            }
        }

        player.ready(function () {
            player.play();
        });
    });
  }
});
